.App {
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  font-family: "Baloo 2";
}
.App-header {
  width: 100%;
  margin-bottom: -4px;
}
.App-footer {
  /* position: absolute;
  bottom: -8px; */
  /* right: 0;
  left: 0;
  width: 100%; */

  /* position:absolute; */
  /* bottom:0; */
  display: flex;
  justify-content: center;
}
.MuiInputBase-root {
  height: 39px;
  align-content: space-evenly;
}
.MuiButton-contained {
  font-family: "Baloo 2";
}
.MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #fff !important;
  border: 1px solid #329159 !important;
  background-color: #176939 !important;
}
.MuiSvgIcon-colorPrimary {
  color: #176939 !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #176939 !important;
  transform: translate(14px, -6px) scale(0.75);
  font-size: 15px;
}
.loader-full-screen {
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 99999;
  left: 0;
  top: 0;
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.loader-full-screen.show {
  display: flex;
}
.flashit {
  color: red;
  -webkit-animation: flash linear 3s infinite;
  animation: flash linear 3s infinite;
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
.dialog-export .MuiDialogContent-dividers {
  padding: 24px 24px;
}

.grid-label {
  padding: 0 0 0 8px !important;
  color: #176939;
  font-size: 12px;
}
.invisible {
  visibility: hidden;
}
