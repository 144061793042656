@import url(https://fonts.googleapis.com/css?family=Baloo%202:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
@import url(https://fonts.googleapis.com/css?family=Arimo:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);

body {
  margin: 0;
  font-family: 'Baloo 2', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:flex; 
  flex-direction:column;
  font-size: 14px;
  height:100%;
}

code {
  font-family: 'Baloo 2',
    monospace;
}
