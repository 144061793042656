@import url(https://fonts.googleapis.com/css?family=Baloo%202:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
@import url(https://fonts.googleapis.com/css?family=Arimo:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);
.App {
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  font-family: "Baloo 2";
}
.App-header {
  width: 100%;
  margin-bottom: -4px;
}
.App-footer {
  /* position: absolute;
  bottom: -8px; */
  /* right: 0;
  left: 0;
  width: 100%; */

  /* position:absolute; */
  /* bottom:0; */
  display: flex;
  justify-content: center;
}
.MuiInputBase-root {
  height: 39px;
  align-content: space-evenly;
}
.MuiButton-contained {
  font-family: "Baloo 2";
}
.MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #fff !important;
  border: 1px solid #329159 !important;
  background-color: #176939 !important;
}
.MuiSvgIcon-colorPrimary {
  color: #176939 !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #176939 !important;
  transform: translate(14px, -6px) scale(0.75);
  font-size: 15px;
}
.loader-full-screen {
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 99999;
  left: 0;
  top: 0;
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.loader-full-screen.show {
  display: flex;
}
.flashit {
  color: red;
  animation: flash linear 3s infinite;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
.dialog-export .MuiDialogContent-dividers {
  padding: 24px 24px;
}

.grid-label {
  padding: 0 0 0 8px !important;
  color: #176939;
  font-size: 12px;
}
.invisible {
  visibility: hidden;
}

.add-to-home-screen-pwa {
  display: table;
  position: fixed;
  text-align: center;
  z-index: 1000;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px;
  width: 100%;
  bottom: 0px;
  box-shadow: 0 -4px 4px rgb(0 0 0 / 4%);
  border: 1px solid #cecece;
  color: #000;
  background: #fff;
}

.add-to-home-screen-pwa__notify {
  display: table-cell;
}

.add-to-home-screen-pwa__notify p {
  font-size: inherit;
  text-rendering: optimizeLegibility;
}

.add-to-home-screen-pwa__notify {
  margin-top: 0.7rem;
}

.add-to-home-screen-pwa__notify p.heading {
  font-weight: 600;
}

.add-to-home-screen-pwa__notify .home,
.share {
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  vertical-align: text-bottom;
}

.add-to-home-screen-pwa__notify .home {
  background-image: url("data:image/svg+xml,<svg height='300' width='300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><switch><g><path d='M81 2.5H19C9.9 2.5 2.5 9.9 2.5 19v62c0 9.1 7.4 16.5 16.5 16.5h62c9.1 0 16.5-7.4 16.5-16.5V19c0-9.1-7.4-16.5-16.5-16.5zm-3.2 51.7H54.2v23.6c0 2.3-1.9 4.2-4.2 4.2-1.2 0-2.2-.5-3-1.2-.8-.8-1.2-1.8-1.2-3V54.2H22.2c-1.2 0-2.2-.5-3-1.2-.8-.8-1.2-1.8-1.2-3 0-2.3 1.9-4.2 4.2-4.2h23.6V22.2c0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2v23.6h23.6c2.3 0 4.2 1.9 4.2 4.2.1 2.3-1.8 4.2-4.2 4.2z'/></g></switch></svg>");
}

.add-to-home-screen-pwa__notify .share {
  background-image: url("data:image/svg+xml,<svg height='300' width='300' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path d='M79.636 28.404H59.515a2.5 2.5 0 1 0 0 5H77.636v55.271H22.364V33.404h18.509a2.5 2.5 0 0 0 0-5H20.364c-1.65 0-3 1.35-3 3v59.271c0 1.65 1.35 3 3 3h59.271c1.65 0 3-1.35 3-3V31.404c.001-1.65-1.349-3-2.999-3z'/><path d='M34.761 23.297l12.801-9.126v46.96a2.439 2.439 0 0 0 4.878 0V14.17l12.802 9.126a2.736 2.736 0 0 0 3.822-.641 2.74 2.74 0 0 0-.641-3.823l-16.832-12a2.74 2.74 0 0 0-3.182 0l-16.832 12a2.741 2.741 0 1 0 3.184 4.465z'/></svg>");
}

.add-to-home-screen-pwa__notify button {
  display: inline-block;
  background-color: #c86b60;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.1em;
  padding: 1.1em 3.134em;
}

body {
  margin: 0;
  font-family: 'Baloo 2', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:flex; 
  flex-direction:column;
  font-size: 14px;
  height:100%;
}

code {
  font-family: 'Baloo 2',
    monospace;
}

